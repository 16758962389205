import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/modules/usuario.store.js";
import storeFiltro from "@/store/modules/filtros.store.js";
import { fetchCliente } from "@/api/gestor/clientes.js";
import { fetchEquipeIndividuo } from "@/api/gestor/empresa_equipe.js";
import { format, parseISO } from "date-fns";

export async function generatePdf(listaAtividades) {
    const filtros_utilizados = { ...storeFiltro.state.filtros };
    const cliente_selecionado = filtros_utilizados.cliente_id ? await fetchCliente(filtros_utilizados.cliente_id) : null;
    const colaborador_selecionado = filtros_utilizados.colaborador_id ? await fetchEquipeIndividuo(filtros_utilizados.colaborador_id) : null;

    return new Promise((resolve) => {
        let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
        let content = [];
        // --------------------------------------------
        let objeto_cabecario = {
            margin: [0, 0, 0, 10],
            fontSize: 10,
            columns: [
                {
                    text: "OLE DESIGNER & COMUNICAÇÃO",
                    alignment: "left",
                    bold: true,
                },
                {
                    text: "LISTA DE ATIVIDADES",
                    alignment: "right",
                    bold: true,
                },
            ],
        };
        content.push(objeto_cabecario);
        // --------------------------------------------
        const filtros_prioridade = [
            {
                id: 1,
                text: 'Baixa'
            },
            {
                id: 2,
                text: 'Normal'
            },
            {
                id: 3,
                text: 'Urgente'
            },
            {
                id: 4,
                text: 'Emergência'
            },
        ]
        const filtro_prioridade_selected = filtros_prioridade.find(item => item.id === filtros_utilizados.prioridade);
        const filtro_prioridade_selecionado = filtro_prioridade_selected ? filtro_prioridade_selected.text : '';
        //---------------------------------------
        const filtros_status = [
            {
                id: -1,
                text: 'cancelado'
            },
            {
                id: 0,
                text: 'a Fazer'
            },
            {
                id: 1,
                text: 'andamento'
            },
            {
                id: 2,
                text: 'espera'
            },
            {
                id: 3,
                text: 'aguardando-gestor'
            },
            {
                id: 4,
                text: 'refazer-via-gestor'
            },
            {
                id: 5,
                text: 'aguardando-cliente'
            },
            {
                id: 6,
                text: 'refazer-via-cliente'
            },
            {
                id: 7,
                text: 'finalizado'
            },
        ]
        const filtro_status = filtros_status.find(item => item.id === filtros_utilizados.status);
        const filtro_status_selecionado = filtro_status ? filtro_status.text : '';
        //---------------------------------------
        const header_table_atividades_filros_text = [];

        if (filtros_utilizados.dataColuna) {
            const key = { text: 'FILTRADO POR DATA DE ', fontSize: 6.5, bold: false };
            const value = { text: filtros_utilizados.dataColuna.toUpperCase(), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.dataFrom) {
            const key = { text: ' | DE: ', fontSize: 6.5, bold: true };
            const value = { text: format(parseISO(filtros_utilizados.dataFrom), "dd/MM/yyyy"), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.dataTo) {
            const key = { text: ' | PARA: ', fontSize: 6.5, bold: true, };
            const value = { text: format(parseISO(filtros_utilizados.dataTo), "dd/MM/yyyy"), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.cliente_id) {
            const key = { text: ' | CLIENTE: ', fontSize: 6.5, bold: true, };
            const value = { text: cliente_selecionado.nome_fantasia.toUpperCase(), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.colaborador_id) {
            const key = { text: ' | COLABORADOR: ', fontSize: 6.5, bold: true, };
            const value = { text: colaborador_selecionado.nome.toUpperCase(), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.prioridade) {
            const key = { text: ' | PRIORIDADE: ', fontSize: 6.5, bold: true, };
            const value = { text: filtro_prioridade_selecionado.toUpperCase(), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        if (filtros_utilizados.status || filtros_utilizados.status === 0) {
            const key = { text: ' | STATUS: ', fontSize: 6.5, bold: true, };
            const value = { text: filtro_status_selecionado.toUpperCase(), fontSize: 6.5, bold: false };
            header_table_atividades_filros_text.push(key, value);
        }

        const header_table_atividades_filtros = {
            text: header_table_atividades_filros_text
        }

        content.push(header_table_atividades_filtros);

        let table_atividades = {
            margin: [0, 5, 0, 5],
            table: {
                widths: ["*", "*", "*", 60, 60, 60, 60],
                body: [
                    [
                        {
                            text: 'titulo'.toUpperCase(),
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                            colSpan: filtros_utilizados.cliente_id ? 2 : 0,
                            fillColor: "#607D8B",
                            color: "white",
                        },
                        {
                            text: 'cliente'.toUpperCase(),
                            bold: true,
                            alignment: "center",
                            fillColor: "#607D8B",
                            color: "white",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },

                        {
                            text: "serviço".toUpperCase(),
                            fillColor: "#607D8B",
                            color: "white",
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },
                        {
                            text: "Inicio".toUpperCase(),
                            fillColor: "#607D8B",
                            color: "white",
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },

                        {
                            text: "Entrega".toUpperCase(),
                            fillColor: "#607D8B",
                            color: "white",
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },
                        {
                            text: "prioridade".toUpperCase(),
                            fillColor: "#607D8B",
                            color: "white",
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },
                        {
                            text: "status".toUpperCase(),
                            fillColor: "#607D8B",
                            color: "white",
                            bold: true,
                            alignment: "center",
                            // margin: [0, 0, 0, 0],
                            fontSize: 8,
                        },
                    ],
                ],
                dontBreakRows: true,
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i <= 1 || i === node.table.body.length ? 1 : 0;
                },
                vLineWidth: function (i, node) {
                    return i === 0 || i === node.table.widths.length ? 0 : 1;
                },
                hLineColor: function (i, node) {
                    return i === 0 || i === node.table.body.length
                        ? "#CFD8DC"
                        : "#CFD8DC";
                },
                vLineColor: function (i, node) {
                    return i === 0 || i === node.table.widths.length
                        ? "#CFD8DC"
                        : "#CFD8DC";
                },
                fillColor: function (rowIndex) {
                    return rowIndex > 0 && rowIndex % 2 === 0 ? "#E0E0E0" : null;
                },
            },
        };

        const row_font_size = 7;
        listaAtividades.forEach((atividade) => {

            let filtro_prioridade =
                atividade.prioridade == 1 ? 'Baixa' :
                    atividade.prioridade == 2 ? 'Normal' :
                        atividade.prioridade == 3 ? 'Urgente' :
                            atividade.prioridade == 4 ? 'Emergência' : '';

            const filtros = [
                {
                    id: -1,
                    text: 'cancelado'
                },
                {
                    id: 0,
                    text: format(new Date(), "yyyy-MM-dd") > atividade.entrega ? 'atrasado' : 'a Fazer'
                },
                {
                    id: 1,
                    text: 'andamento'
                },
                {
                    id: 2,
                    text: 'espera'
                },
                {
                    id: 3,
                    text: 'aguardando-gestor'
                },
                {
                    id: 4,
                    text: 'refazer-via-gestor'
                },
                {
                    id: 5,
                    text: 'aguardando-cliente'
                },
                {
                    id: 6,
                    text: 'refazer-via-cliente'
                },
                {
                    id: 7,
                    text: 'finalizado'
                },
            ]

            const filtro = filtros.find(item => item.id === atividade.status);

            const filtro_status = filtro ? filtro.text : 'status nao encontrato';

            let table_row = [
                {
                    text: atividade.titulo,
                    fontSize: row_font_size,
                    alignment: "left",
                    margin: [0, 0, 0, 0],
                    border: [true, false, true, true],
                    colSpan: filtros_utilizados.cliente_id ? 2 : 0
                },
                {
                    text: atividade.cliente_nome_fantasia,
                    fontSize: row_font_size,
                    alignment: "left",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
                {
                    text: atividade.cliente_servico,
                    fontSize: row_font_size,
                    alignment: "left",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
                {
                    text: format(parseISO(atividade.inicio), "dd/MM/yyyy"),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
                {
                    text: format(parseISO(atividade.entrega), "dd/MM/yyyy"),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
                {
                    text: filtro_prioridade.toUpperCase(),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
                {
                    text: filtro_status.toUpperCase(),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 0, 0, 0],
                    border: [false, false, true, true],
                },
            ];

            table_atividades.table.body.push(table_row);
        });

        content.push(table_atividades);

        let nome = store.state.usuario.name;
        let docDefinition = {
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: "landscape", //"portrait"
            defaultStyle: {
                font: "Roboto",
                columnGap: 20,
                lineHeight: 1.1,
            },
            content,
            footer(currentPage, pageCount) {
                return [
                    {
                        columns: [
                            {
                                text: `Impresso por ${nome} as ${now}`,
                                fontSize: 7,
                                margin: [25, 0, 0, 0],
                            },
                            {
                                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                                margin: [0, 0, 25, 0],
                                alignment: "right",
                                fontSize: 7,
                            },
                        ],
                    },
                ];
            },
            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                },
                subheader: {
                    fontSize: 10,
                },
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        // pdfDocGenerator.open();

        pdfDocGenerator.getDataUrl((data) => {
            resolve(data);
        });
    });
}
