import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/modules/usuario.store.js";
import { format } from "date-fns";

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function() {
//     var reader = new FileReader();
//     reader.onloadend = function() {
//       callback(reader.result);
//     }
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// }

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function(dataUrl) {
//   console.log('RESULT:', dataUrl)
// })

// const toDataURL = url => fetch(url)
//   .then(response => response.blob())
//   .then(blob => new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.onloadend = () => resolve(reader.result)
//     reader.onerror = reject
//     reader.readAsDataURL(blob)
//   }))

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0')
//   .then(dataUrl => {
//     console.log('RESULT:', dataUrl)
//   })

export function generatePdf(content) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");

    let nome = store.state.usuario.name;
    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `Impresso por ${nome} as ${now}`,
                fontSize: 7,
                margin: [25, 0, 0, 0],
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 25, 0],
                alignment: "right",
                fontSize: 7,
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
