import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/modules/usuario.store.js";
import { format, parseISO } from "date-fns";

export function generatePdf(listaAtividades) {
    return new Promise((resolve) => {
        let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
        let content = [];

        let objeto_cabecario = {
            margin: [0, 0, 0, 10],
            fontSize: 8,
            columns: [
                {
                    text: "OLE DESIGNER & COMUNICAÇÃO",
                    alignment: "left",
                },
                {
                    text:
                        "CRUZ E BETTO PRESTADORA DE SERVICOS EM DESIGN LTDA | CNPJ: 08.004.795/0001-18 | Telefone" +
                        "(45) 3025-7263 | Endereço Rua Engenheiro Rebouças, 434 | Bairro: Centro | CEP: 85851-190 | Cidade: Foz" +
                        "do Iguaçu | Estado: PR",
                    alignment: "center",
                },
            ],
        };

        content.push(objeto_cabecario);

        let header_atividades = {
            text: "LISTA DE ATIVIDADES",
            alignment: "center",
        }

        content.push(header_atividades);

        let table_atividades = {
            margin: [0, 5, 0, 5],
            table: {

                widths: ["*", "*", "*", "*", "*", "*", "*"],
                body: [
                    [
                        {
                            text: 'titulo'.toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },
                        {
                            text: 'cliente'.toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },

                        {
                            text: "serviço".toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },
                        {
                            text: "Inicio".toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },

                        {
                            text: "Entrega".toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },
                        {
                            text: "prioridade".toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },
                        {
                            text: "status".toUpperCase(),
                            bold: true,
                            alignment: "center",
                            margin: [0, 4, 0, 2],
                            fontSize: 8,
                        },
                    ],
                ],
                dontBreakRows: true,
            },
            layout: {
                hLineWidth: function (i, node) {
                    return i <= 1 || i === node.table.body.length ? 1 : 1;
                },
                hLineColor: function (i, node) {
                    return i === 0 || i === node.table.body.length
                        ? "#666666"
                        : "#666666";
                },
                vLineColor: function (i, node) {
                    return i === 0 || i === node.table.widths.length
                        ? "#666666"
                        : "#666666";
                },
                fillColor: function (rowIndex) {
                    return rowIndex > 0 && rowIndex % 2 === 0 ? "#F5F5F5" : null;
                },
            },
        };

        const row_font_size = 7;
        listaAtividades.forEach((atividade) => {

            let filtro_prioridade =
                atividade.prioridade == 1 ? 'Baixa' :
                    atividade.prioridade == 2 ? 'Normal' :
                        atividade.prioridade == 3 ? 'Urgente' :
                            atividade.prioridade == 4 ? 'Emergência' : '';

            const filtros = [
                {
                    id: -1,
                    text: 'cancelado'
                },
                {
                    id: 0,
                    text: format(new Date(), "yyyy-MM-dd") > atividade.entrega ? 'atrasado' : 'a Fazer'
                },
                {
                    id: 1,
                    text: 'andamento'
                },
                {
                    id: 2,
                    text: 'espera'
                },
                {
                    id: 3,
                    text: 'aguardando-gestor'
                },
                {
                    id: 4,
                    text: 'refazer-via-gestor'
                },
                {
                    id: 5,
                    text: 'aguardando-cliente'
                },
                {
                    id: 6,
                    text: 'refazer-via-cliente'
                },
                {
                    id: 7,
                    text: 'finalizado'
                },
            ]

            const filtro = filtros.find(item => item.id === atividade.status);

            const filtro_status = filtro ? filtro.text : 'status nao encontrato';

            let table_row = [
                {
                    text: atividade.titulo,
                    fontSize: row_font_size,
                    alignment: "start",
                    margin: [0, 6, 0, 6],
                    border: [true, false, false, true],
                },
                {
                    text: atividade.cliente_nome_fantasia,
                    fontSize: row_font_size,
                    alignment: "start",
                    margin: [0, 6, 0, 6],
                    border: [false, false, false, true],
                },
                {
                    text: atividade.servico.descricao,
                    fontSize: row_font_size,
                    alignment: "start",
                    margin: [0, 6, 0, 6],
                    border: [false, false, false, true],
                },
                {
                    text: format(parseISO(atividade.inicio), "dd/MM/yyyy"),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 6, 0, 6],
                    border: [false, false, false, true],
                },
                {
                    text: format(parseISO(atividade.entrega), "dd/MM/yyyy"),
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 6, 0, 6],
                    border: [false, false, true, true],
                },
                {
                    text: filtro_prioridade,
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 6, 0, 6],
                    border: [false, false, true, true],
                },
                {
                    text: filtro_status,
                    fontSize: row_font_size,
                    alignment: "center",
                    margin: [0, 6, 0, 6],
                    border: [false, false, true, true],
                },
            ];

            table_atividades.table.body.push(table_row);
        });

        content.push(table_atividades);

        let nome = store.state.usuario.name;
        let docDefinition = {
            pageSize: "A4",
            pageMargins: [15, 15, 15, 15],
            pageOrientation: "portrait", //"landscape"
            defaultStyle: {
                font: "Roboto",
                columnGap: 20,
                lineHeight: 1.1,
            },
            content,
            footer(currentPage, pageCount) {
                return [
                    {
                        columns: [
                            {
                                text: `Impresso por ${nome} as ${now}`,
                                fontSize: 7,
                                margin: [25, 0, 0, 0],
                            },
                            {
                                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                                margin: [0, 0, 25, 0],
                                alignment: "right",
                                fontSize: 7,
                            },
                        ],
                    },
                ];
            },
            styles: {
                header: {
                    fontSize: 16,
                    bold: true,
                },
                subheader: {
                    fontSize: 10,
                },
            },
        };

        pdfMake.fonts = {
            Roboto: {
                normal: "Roboto-Regular.ttf",
                bold: "Roboto-Medium.ttf",
            },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        // pdfDocGenerator.open();

        pdfDocGenerator.getDataUrl((data) => {
            resolve(data);
        });
    });
}
