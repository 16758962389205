import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/index.js";
import { format } from "date-fns";

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function() {
//     var reader = new FileReader();
//     reader.onloadend = function() {
//       callback(reader.result);
//     }
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// }

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function(dataUrl) {
//   console.log('RESULT:', dataUrl)
// })

// const toDataURL = url => fetch(url)
//   .then(response => response.blob())
//   .then(blob => new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.onloadend = () => resolve(reader.result)
//     reader.onerror = reject
//     reader.readAsDataURL(blob)
//   }))

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0')
//   .then(dataUrl => {
//     console.log('RESULT:', dataUrl)
//   })

export function generatePdf(empresa) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.user_name;

    let campos_margin = [0, 0, 0, 15];

    let logo = {
      image: empresa.logo64,
      width: 150,
      alignment: "center",
      margin: [0, 0, 0, 25],
    };

    let content = [
      // LOGO
      empresa.logo64 ? logo : "",

      // CAMPOS

      // Razão Social
      {
        stack: [
          {
            text: "Razão Social",
            fontSize: 8,
          },
          {
            text: empresa.razao_social,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Nome Fantasia
      {
        stack: [
          {
            text: "Nome Fantasia",
            fontSize: 8,
          },
          {
            text: empresa.nome_fantasia,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Telefone
      {
        stack: [
          {
            text: "Telefone",
            fontSize: 8,
          },
          {
            text: empresa.telefone,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Celular
      {
        stack: [
          {
            text: "Celular",
            fontSize: 8,
          },
          {
            text: empresa.celular,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Email
      {
        stack: [
          {
            text: "E-mail",
            fontSize: 8,
          },
          {
            text: empresa.email,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Cnpj
      {
        stack: [
          {
            text: "Cnpj",
            fontSize: 8,
          },
          {
            text: empresa.cnpj,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Ramo de Negócio
      {
        stack: [
          {
            text: "Ramo de Negócio",
            fontSize: 8,
          },
          {
            text: empresa.ramo_negocio,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Site
      {
        stack: [
          {
            text: "Site",
            fontSize: 8,
          },
          {
            text: empresa.site,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      //CI
      {
        stack: [
          {
            text: "CI ",
            fontSize: 8,
          },
          {
            text: empresa.ci,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
      // Endereço
      {
        stack: [
          {
            text: "Localização",
            fontSize: 8,
          },
          {
            text: `${empresa.endereco} ${
              empresa.bairro ? ", " + empresa.bairro : ""
            } ${empresa.numero ? ", " + empresa.numero : ""} ${
              empresa.cidade ? " - " + empresa.cidade : ""
            } ${empresa.uf ? "/" + empresa.uf : ""}`,
            fontSize: 10,
            margin: campos_margin,
          },
        ],
      },
    ];

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `Impresso por ${nome} as ${now}`,
                fontSize: 7,
                margin: [25, 0, 0, 0],
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 25, 0],
                alignment: "right",
                fontSize: 7,
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
