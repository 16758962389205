import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import store from "@/store/index.js";
import {
  format,
  parseISO,
  getWeeksInMonth,
  getWeekOfMonth,
  getDaysInMonth,
  getMonth,
  getYear,
  getDay,
  isEqual,
  isWithinInterval,
} from "date-fns";

// function toDataURL(url, callback) {
//   var xhr = new XMLHttpRequest();
//   xhr.onload = function() {
//     var reader = new FileReader();
//     reader.onloadend = function() {
//       callback(reader.result);
//     }
//     reader.readAsDataURL(xhr.response);
//   };
//   xhr.open('GET', url);
//   xhr.responseType = 'blob';
//   xhr.send();
// }

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0', function(dataUrl) {
//   console.log('RESULT:', dataUrl)
// })

// const toDataURL = url => fetch(url)
//   .then(response => response.blob())
//   .then(blob => new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.onloadend = () => resolve(reader.result)
//     reader.onerror = reject
//     reader.readAsDataURL(blob)
//   }))

// toDataURL('https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0')
//   .then(dataUrl => {
//     console.log('RESULT:', dataUrl)
//   })

export function generatePdf(calendario, postagensCalendarios) {
  return new Promise((resolve) => {
    let now = format(new Date(), "HH:mm:ss - dd/MM/yyyy");
    let nome = store.state.Usuario.user_name;

    let ano_calendario = getYear(parseISO(calendario.inicio));
    let mes_calendario = getMonth(parseISO(calendario.inicio)) + 1;
    let qtd_dias = getDaysInMonth(parseISO(calendario.inicio));
    let qtd_semanas = getWeeksInMonth(parseISO(calendario.inicio));



    let tabela_body = [];
    tabela_body.push([
      "Domingo",
      "Segunda",
      "Terça",
      "Quarta",
      "Quinta",
      "Sexta",
      "Sábado",
    ]);

    for (let count_semana = 1; count_semana <= qtd_semanas; count_semana++) {
      let semana = [0, 1, 2, 3, 4, 5, 6];
      let construtor_semana = [" ", " ", " ", " ", " ", " ", " "];

      for (let count_dia = 1; count_dia <= qtd_dias; count_dia++) {
        let data = parseISO(
          `${ano_calendario}-${
            mes_calendario < 10 ? "0" + mes_calendario : mes_calendario
          }-${count_dia < 10 ? "0" + count_dia : count_dia}`
        );

        let semana_do_mes = getWeekOfMonth(data);
        let dia_semana_data = getDay(data);

        semana.forEach((dia_semana) => {
          if (semana_do_mes === count_semana) {
            if (dia_semana_data === dia_semana) {
              construtor_semana.splice(dia_semana, 1);

              let postagens_calendario = postagensCalendarios.filter((item) =>
                isEqual(data, parseISO(item.data))
              );

              if (!postagens_calendario.length) {
                let ta_no_intervalo = isWithinInterval(data, {
                  start: parseISO(calendario.inicio),
                  end: parseISO(calendario.fim),
                });

                let obj = {
                  text: count_dia,
                  fillColor: ta_no_intervalo ? "" : "#f5f5f5",
                };

                construtor_semana.splice(dia_semana, 0, obj);
              } else {
                let postagem = [];
                postagem.push(count_dia + "\n");
                let tabela_postagens = {
                  table: {
                    widths: [1, "*"],
                    body: [],
                  },
                  layout: {
                    hLineWidth: function() {
                      return 5;
                    },
                    vLineWidth: function() {
                      return null;
                    },
                    hLineColor: function() {
                      return "white";
                    },
                    vLineColor: function() {
                      return "white";
                    },
                  },
                };
                let quantidadePostagem = 1;

                postagens_calendario.forEach((postagem_calendario) => {
                  if (quantidadePostagem < 4) {
                    let body_line = [
                      {
                        fillColor: postagem_calendario.tema.cor,
                        border: [true, true, true, true],
                        text: "",
                        fontSize: 7,
                      },
                      {
                        border: [false, true, false, true],
                        fillColor: "#f5f5f5",
                        text:
                          postagem_calendario.hora +
                          " - " +
                          postagem_calendario.assunto,
                        margin: [0, 0, 0, 0],
                        fontSize: 7,
                      },
                    ];
                    tabela_postagens.table.body.push(body_line);
                    quantidadePostagem = quantidadePostagem + 1;
                  } else {
                    let body_line = [
                      {
                        border: [true, false, false, true],
                        text: " ",
                        margin: [0, 0, 0, 0],
                        alignment: "left",
                        fontSize: 7,
                      },
                      {
                        border: [false, false, false, true],
                        text: `mais ${quantidadePostagem - 3} postagem...`,
                        margin: [0, 0, 0, 0],
                        fontSize: 7,
                        alignment: "left",
                      },
                    ];
                    tabela_postagens.table.body.splice(3, 1, body_line);
                    quantidadePostagem = quantidadePostagem + 1;
                  }
                });
                postagem.push(tabela_postagens);
                construtor_semana.splice(dia_semana, 0, postagem);
              }
            }
          }
        });
      }

      tabela_body.push(construtor_semana);
    }

    let content = [
      {
        stack: [
          {
            margin: [0, 0, 0, 10],
            fontSize: 10,
            columns: [
              {
                text: "Postagens das Redes Sociais",
                alignment: "left",
              },
              {
                text:
                  "Período: " +
                  format(parseISO(calendario.inicio), "dd/MM/yyyy") +
                  " à " +
                  format(parseISO(calendario.fim), "dd/MM/yyyy"),
                alignment: "center",
              },
              {
                text: "Cliente: " + calendario.cliente,
                alignment: "right",
              },
            ],
          },

          {
            margin: [0, 0, 0, 0],
            table: {
              widths: ["*", "*", "*", "*", "*", "*", "*"],
              heights: [20, 80, 80, 80, 80, 80],
              body: tabela_body,
            },
          },
        ],
      },
      {
        margin: [0, 10, 0, 5],
        columns: [
          {
            width: 47,
            text: 'Legendas:',
            fontSize: 10,
            margin: [0, 0, 0, 0],
          },
          {
            width: 65,
            stack: [
              {
                canvas: [
                  { type: 'rect', x: 0, y: 0, w: 10, h: 10, color: '#D84315' },
                ],
                width: 20,
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Comemorativo',
                fontSize: 8,
                alignment: 'left',
                margin: [12, -10, 0, 0], // Ajuste para alinhar a descrição com a cor
              },
            ],
          },
          {
            width: 60,
            stack: [
              {
                canvas: [
                  { type: 'rect', x: 0, y: 0, w: 10, h: 10, color: '#689F38' },
                ],
                width: 20,
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Comercial',
                fontSize: 8,
                alignment: 'left',
                margin: [12, -10, 0, 0], // Ajuste para alinhar a descrição com a cor
              },
            ],
          },
          {
            width: 60,
            stack: [
              {
                canvas: [
                  { type: 'rect', x: 0, y: 0, w: 10, h: 10, color: '#29B6F6' },
                ],
                width: 20,
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Institucional',
                fontSize: 8,
                alignment: 'left',
                margin: [12, -10, 0, 0], // Ajuste para alinhar a descrição com a cor
              },
            ],
          },
          {
            width: 60,
            stack: [
              {
                canvas: [
                  { type: 'rect', x: 0, y: 0, w: 10, h: 10, color: '#FFEB3B' },
                ],
                width: 20,
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Consultivo',
                fontSize: 8,
                alignment: 'left',
                margin: [12, -10, 0, 0], // Ajuste para alinhar a descrição com a cor
              },
            ],
          },
          {
            width: 60,
            stack: [
              {
                canvas: [
                  { type: 'rect', x: 0, y: 0, w: 10, h: 10, color: '#78909C' },
                ],
                width: 20,
                margin: [0, 0, 0, 0],
              },
              {
                text: 'Feriado',
                fontSize: 8,
                alignment: 'left',
                margin: [12, -10, 0, 0], // Ajuste para alinhar a descrição com a cor
              },
            ],
          }
        ]
      },
    ];

    let docDefinition = {
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columnGap: 20,
        lineHeight: 1.1,
      },
      content,
      footer(currentPage, pageCount) {
        return [
          {
            columns: [
              {
                text: `Impresso por ${nome} as ${now}`,
                fontSize: 7,
                margin: [25, 0, 0, 0],
              },
              {
                text: `Pagina: ${currentPage.toString()} de ${pageCount}`,
                margin: [0, 0, 25, 0],
                alignment: "right",
                fontSize: 7,
              },
            ],
          },
        ];
      },
      styles: {
        header: {
          fontSize: 16,
          bold: true,
        },
        subheader: {
          fontSize: 10,
        },
      },
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);
    // pdfDocGenerator.open();

    pdfDocGenerator.getDataUrl((data) => {
      resolve(data);
    });
  });
}
