import api from "../axios_service.js";

export async function fetchEquipe(filtros) {
  const response = await api.get(
    `/gestor/empresa-equipe${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchEquipeIndividuo(id) {
  const response = await api.get(`/gestor/empresa-equipe/${id}`);
  return response.data.result;
}

export function putEquipeIndividuo(id, item) {
  let body = {};
  for (let key in item) {
    if (key != "acesso" && key != "cargo") {
      body[key] = item[key];
    }
  }
  return api.put(`/gestor/empresa-equipe/${id}`, body);
}

export function putEquipeIndividuoImagem(id, item) {
  return api.post(`/gestor/empresa-equipe/${id}`, item);
}

export async function postEquipeIndividuo(item) {
  const response = await api.post("/gestor/empresa-equipe/add", item);
  return response;
}

export async function uploadAvatarColaborador(id, body) {
  const response = await api.post(`/gestor/empresa-equipe/${id}/avatar`, body);
  return response;
}
